import austriaFlag from '../images/countryIcon/emojione_flag-for-austria.svg'
import chinaFlag from '../images/countryIcon/emojione_flag-for-china.svg'
import croatiaFlag from '../images/countryIcon/emojione_flag-for-croatia.svg'
import czechiaFlag from '../images/countryIcon/emojione_flag-for-czechia.svg'
import denmarkFlag from '../images/countryIcon/emojione_flag-for-denmark.svg'
import finlandFlag from '../images/countryIcon/emojione_flag-for-finland.svg'
import franceFlag from '../images/countryIcon/emojione_flag-for-france.svg'
import greeceFlag from '../images/countryIcon/emojione_flag-for-greece.svg'
import hongkongFlag from '../images/countryIcon/emojione_flag-for-hong-kong-sar-china.svg'
import hungaryFlag from '../images/countryIcon/emojione_flag-for-hungary.svg'
import indiaFlag from '../images/countryIcon/emojione_flag-for-india.svg'
import indonesiaFlag from '../images/countryIcon/emojione_flag-for-indonesia.svg'
import irelandFlag from '../images/countryIcon/emojione_flag-for-ireland.svg'
import israelFlag from '../images/countryIcon/emojione_flag-for-israel.svg'
import italyFlag from '../images/countryIcon/emojione_flag-for-italy.svg'
import japanFlag from '../images/countryIcon/emojione_flag-for-japan.svg'
import latviaFlag from '../images/countryIcon/emojione_flag-for-latvia.svg'
import malaysiaFlag from '../images/countryIcon/emojione_flag-for-malaysia.svg'
import mexicoFlag from '../images/countryIcon/emojione_flag-for-mexico.svg'
import norwayFlag from '../images/countryIcon/emojione_flag-for-norway.svg'
import philippinesFlag from '../images/countryIcon/emojione_flag-for-philippines.svg'
import polandFlag from '../images/countryIcon/emojione_flag-for-poland.svg'
import romaniaFlag from '../images/countryIcon/emojione_flag-for-romania.svg'
import singaporeFlag from '../images/countryIcon/emojione_flag-for-singapore.svg'
import southKoreaFlag from '../images/countryIcon/emojione_flag-for-south-korea.svg'
import spainFlag from '../images/countryIcon/emojione_flag-for-spain.svg'
import swedenFlag from '../images/countryIcon/emojione_flag-for-sweden.svg'
import taiwanFlag from '../images/countryIcon/emojione_flag-for-taiwan.svg'
import turkeyFlag from '../images/countryIcon/emojione_flag-for-turkey.svg'
import vietnamFlag from '../images/countryIcon/emojione_flag-for-vietnam.svg'


export const CountryList = [
    {
        name: 'Austria',
        flag: austriaFlag
    },
    {
        name: 'China',
        flag: chinaFlag
    },
    {
        name: 'Croatia',
        flag: croatiaFlag
    },
    {
        name: 'Czechia',
        flag: czechiaFlag
    },
    {
        name: 'Denmark',
        flag: denmarkFlag
    },
    {
        name: 'Finland',
        flag: finlandFlag
    },
    {
        name: 'France',
        flag: franceFlag
    },
    {
        name: 'Greece',
        flag: greeceFlag
    },
    {
        name: 'Hong Kong',
        flag: hongkongFlag
    },
    {
        name: 'Hungary',
        flag: hungaryFlag
    },
    {
        name: 'India',
        flag: indiaFlag
    },
    {
        name: 'Indonesia',
        flag: indonesiaFlag
    },
    {
        name: 'Ireland',
        flag: irelandFlag
    },
    {
        name: 'Israel',
        flag: israelFlag
    },
    {
        name: 'Italy',
        flag: italyFlag
    },
    {
        name: 'Japan',
        flag: japanFlag
    },
    {
        name: 'Latvia',
        flag: latviaFlag
    },
    {
        name: 'Malaysia',
        flag: malaysiaFlag
    },
    {
        name: 'Mexico',
        flag: mexicoFlag
    },
    {
        name: 'Norway',
        flag: norwayFlag
    },
    {
        name: 'Philippines',
        flag: philippinesFlag
    },
    {
        name: 'Poland',
        flag: polandFlag
    },
    {
        name: 'Romania',
        flag: romaniaFlag
    },
    {
        name: 'Singapore',
        flag: singaporeFlag
    },
    {
        name: 'South Korea',
        flag: southKoreaFlag
    },
    {
        name: 'Spain',
        flag: spainFlag
    },
    {
        name: 'Sweden',
        flag: swedenFlag
    },
    {
        name: 'Taiwan',
        flag: taiwanFlag
    },
    {
        name: 'Turkey',
        flag: turkeyFlag
    },
    {
        name: 'Vietnam',
        flag: vietnamFlag
    },
]