const LineBox = () => {
    return (

        <svg width="100" height="1" viewBox="0 0 100 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="0.5" x2="100" y2="0.5" stroke="#EEEEEE" stroke-opacity="0.2" />
        </svg>

    )
}

export default LineBox
