import React, { useEffect } from "react";

import { Box, Container, Typography } from "@mui/material";

export default function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container maxWidth="lg">
      <Box py="2rem">
        <Typography component="h3" textAlign="center" py="1rem">
          SECUREFIELD VPN TERMS OF SERVICE
        </Typography>

        <Typography component="h4">IMPORTANT:</Typography>
        <Typography>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the Securefield VPN mobile application (the "Service") operated by Securefield VPN ("us", "we", or "our"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</Typography>
        <Typography>The Securefield VPN service enables users to choose their virtual location by routing their Internet traffic through a virtual private network. The Service is offered by Securefield Team.</Typography>
        <Typography>
          Should You have any questions concerning this Agreement or any of the terms herein, You may contact{" "}
          <a href="mailto:support@securefieldapp.com" style={{ color: "#35DF91", position: "relative", zIndex: 1 }}>
            support@securefieldapp.com
          </a>
          .
        </Typography>
        <Typography component="h3">Privilege &amp; Registration</Typography>
        <Typography>The utilization of the Service is restricted to parties that can legally enter into and form contracts under applicable law. Every person who uses the Services hereby represents to the application that he or she is more than 18 years of age, and is fully able and authorized to enter into the terms, provisions, necessities, affirmations, representations, and warranties set forth in this Agreement and to abide by and comply with the terms of this Agreement. If You are under 18 years of age, please do not use the Services.</Typography>
        <Typography component="h3">Service Description</Typography>
        <Typography>The Service is a virtual private network communications service that contains software, that interacts with Our servers (or third party contractors' servers) which allow You to create a virtual private connection. The Software on Your Device connects to a server network infrastructure that is deployed on the internet and operated as a managed service by Us and other service providers. All or some of the Service ensured hereunder may be provided by a third-party provider.</Typography>
        <Typography component="h3">Impersonal Information Securefield collects and How We Use It:</Typography>
        <Typography>You will have specific rights to use the Software and reach the Service during the Service Period. The “Service Period” shall begin on either the date of purchase of the Service, if You purchased a subscription for the Service online, or on the date You activate the Service, if You acquired the Service through other means (for example, if You are activating a free or trial version of the Service), and shall continue for the period of time set forth in the Service documentation.</Typography>
        <Typography component="h3">About Service and Software</Typography>
        <Typography>Besides, all materials included with the Software and Service, including all trademarks, service marks, and trade names are the property of the application and its third party licensors. You do not obtain any rights, express or implied, in the Software and Service, other than those specified in this Agreement. To the extent permissible by applicable law, You may not, nor may You allow any other person or entity to:</Typography>
        <Typography component="ul">
          <li>redeliver or lease any portion of the Service or the Software;</li>
          <li>reverse engineer, decompile, disassemble, modify, translate, make any attempt to discover the source code of the Software or create derivative works from the Software or Service;</li>
          <li>reproduce, replicate, copy, sell, trade, resell or exploit for any commercial aims, any portion or use of, or access to, the Software or Service;</li>
          <li>copy, reproduce, capture, store, retransmit, distribute, or burn to CD (or any other format or device) any copyrighted content that You access or receive while using the Software or Service.</li>
        </Typography>
        <Typography>You assume all risk and liability for any such prohibition use of copyrighted material.</Typography>
        <Typography component="h3">Acceptable Use</Typography>
        <Typography>You are and will be solely liable for Your conduct related to the Service. You specifically admit that You shall abide by and cooperate with Us to enforce and/or prevent potential violations of the Digital Millennium Copyright Act and any other similar statutes to which You are subject, such as in the European Union the national implementations of Directive 2001/29/EC of the European Parliament and of the Council of 22 May 2001 on the harmonization of certain viewpoints of copyright and related rights in the information society. In the United Kingdom these are included in the Copyright, Designs and Patents Act 1988. You further agree that You will not use the Service:</Typography>
        <Typography component="ul">
          <li>in violation of any practicable laws or regulations, or to support the violation of any applicable laws or regulations;</li>
          <li>to upload, post, or otherwise transfer any content which is obscene, indecent, or pornographic, or which is otherwise objectionable;</li>
          <li>in a unfair or deceptive manner;</li>
          <li>to upload, post, or otherwise transfer content which You do not have a right to or which would otherwise violate the intellectual property rights of any party;</li>
          <li>to defame, abuse, harass, stalk, threaten or to violate the legal rights of others or to take action which is overriding of the privacy rights of others;</li>
          <li>to upload, post, or otherwise transfer any content that contains viruses or other harmful computer code or files such as Trojan horse, worms, time bombs, or the like;</li>
          <li>to upload, post, or otherwise transfer any unsolicited or unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid schemes” or any other form of solicitation;</li>
          <li>Illegal or unauthorized access to other computers, mobile devices or networks;</li>
          <li>use of torrents for illegal downloads;</li>
          <li>to attempt to gain unauthorized access to the Service, the accounts of other Service users, or mobile systems or networks connected to the Service; or to otherwise interrupt or disrupt the Service or servers or networks connected to the Service</li>
          <li>delete the copyright or other proprietary rights on the Software or Service;</li>
          <li>use the Service or Software for any commercial use without the prior written approval of Us, it being understood that unless specifically provided otherwise in the respective Service documentation, the Software and Service are for personal, non-commercial use only;</li>
          <li>use the Software or Service if You are under the age of 18 years old;</li>
          <li>remove, circumvent, disable, damage or otherwise interfere with security-related features of the Software or Service, features that hinder or restrict use or copying of the Software, or features that enforce limitations on the use of the Service;</li>
          <li>reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the Service or Software or any part thereof, except and only to the extent that such activity is expressly allowed by applicable law still this limitation; or</li>
          <li>modify, adapt, translate or create derivative works based upon the Software or Service or any part thereof, except and only to the extent prior restriction is expressly forbid by applicable law.</li>
        </Typography>
        <Typography>You understand that, still Our efforts and the terms contained in this Agreement, You may be exposed to content which You find to be offensive, inappropriate or undesirable when using the Service, and, accordingly, You use the Service at Your own risk of being exposed to such content.</Typography>
        <Typography component="h3">Amendments to the Service; Amendments to Terms of Service</Typography>
        <Typography>
          You shall have the right to receive new properties to the Software and Service. We frequently endeavor to improve the utilizability and performance of its products and services. In order to optimize the Software and Service we may, at discretion: add, modify or remove features of the Software and Services, or temporarily limit the use of the Software and Services or limit its availability in order to perform maintenance activities, at any time with or without notice to You. We may also update the terms of this Agreement and recommend that You check the terms of this Agreement regularly to see if they’ve been updated. We will post modifications on{" "}
          <a href="https://www.securefieldapp.com" style={{ color: "#35DF91", position: "relative", zIndex: 1 }}>
            www.securefieldapp.com
          </a>{" "}
          (or such other URL that we may provide from time to time). You agree that You will be considered to have been given notice of any modifications once we post them to{" "}
          <a href="https://www.securefieldapp.com" style={{ color: "#35DF91", position: "relative", zIndex: 1 }}>
            www.securefieldapp.com
          </a>
          . Your continued use of the Service will be esteemed to be Your compliance of the updated terms of this Agreement.
        </Typography>
        <Typography component="h3">Rescission and Suspension</Typography>
        <Typography>Your right to use the Service shall terminate upon expiration of the Service Period. In addition, We may, at discretion, immediately suspend or terminate Your use of the Service at any time with or without notice to You if You violate, or we reasonably believe that You have violated, the terms of this Agreement, or if Your use of the Service may expose Us to risk of harm or loss.</Typography>
        <Typography>After such termination or suspension, Your right to use the Software and Service will immediately cease. We will not have any liability whatsoever to You for any suspension or termination, including for deletion of Your Account Information. All provisions of this Agreement, which by their nature shall survive termination, shall survive including, without limitation, indemnity, warranty disclaimers, and limitations of liability.</Typography>
        <Typography component="h3">Use of the Securefield Software and Service</Typography>
        <Typography>The utilization of the Software and Service shall be regulated by the laws of the United States and by the laws of the State of California, without regard to its conflict of law terms.</Typography>
      </Box>
    </Container>
  );
}
